











































































import Vue from 'vue';
import { getSmsCode, changePass, checkMobile } from '@/api/auth';
import { isMobile } from '@/utils/validate';
import { countDown } from '@/utils';
import mixin from '@/views/login/mixin';
import { SMS_CODE_TYPE } from '@/interface';

export default Vue.extend({
  mixins: [mixin],
  data() {
    const validateMobile = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'));
      }
      if (!isMobile(value)) {
        return callback(new Error('手机号码格式有误'));
      }
    };
    const validPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'));
      }
      if (value.length < 6 || value.length > 20) {
        return callback(new Error('密码长度为6-20个字符'));
      }
      if (this['formState'].pwd2) {
        (this['$refs']['formRef'] as any).validateField('pwd2');
      }
      callback();
    };
    const validPass2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'));
      }
      if (value !== this['formState'].pwd) {
        return callback(new Error('两次密码输入不一致'));
      }
      callback();
    };
    return {
      formState: {
        mobile: '',
        mobileVerifyCode: '',
        pwd: '',
        pwd2: '',
      },
      rules: {
        mobile: [
          { required: true, validator: validateMobile, trigger: 'blur' },
        ],
        mobileVerifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        pwd: [{ required: true, validator: validPass, trigger: 'blur' }],
        pwd2: [{ required: true, validator: validPass2, trigger: 'blur' }],
      },
      countdown: {},
      loading: false,
    };
  },
  created() {
    this.countdown = countDown(60);
  },
  methods: {
    getVerifyCode() {
      if (!isMobile(this.formState.mobile)) {
        this.$message.error('手机号码格式有误');
        return;
      }
      checkMobile(this.formState.mobile, SMS_CODE_TYPE.CHANGE_PASSWORD).then(
        () => {
          this.countdown['begin']();
          getSmsCode(this.formState.mobile, SMS_CODE_TYPE.CHANGE_PASSWORD).then(
            () => {
              this.$message.success('已发送');
            }
          );
        }
      );
    },
    handleSubmit() {
      (this.$refs['formRef'] as any).validate((valid: boolean) => {
        if (!valid) return;
        this.loading = true;
        changePass(this.formState)
          .then(() => this.$message.success('修改成功'))
          .then(() => {
            this.$store.dispatch('logout');
            this.$router.replace({ path: '/' });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
});
